.iconList {
  margin: 1em 1em;
  padding: 0.5em;
}

.iconListItem {
  list-style: none;
  background-repeat: no-repeat;
  background-position: center left;
  margin: 1.5em 0;
  padding-left: 64px;
  vertical-align: middle;
  min-height: 24px;
}

.iconListItem:first-child {
  margin-top: 0;
}
