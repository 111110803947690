.theSVG {
}
.svgContents {
  transform-origin: center;
  transform: translate(0, -37px) scale(1.37);
}

.cube {
  transform-origin: center;
  fill: black;
}


.a, .b, .c, .d, .e, .f, .g, .h, .i, .j, .k, .l, .m, .n, .o, .p, .q, .r, .s, .t, .u, .v, .w, .x, .y, .z, ._, {
}

.top, .left, .right {
  cursor: pointer;
}

.top path, .left path, .right path {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease;
}

.top path:hover, .left path:hover, .right path:hover {
  /* filter: drop-shadow(0 0 100px rgba(255, 255, 255, 0.3)); */
}

.top path:hover {
  transform: translate(0, -0.4%);
}

.left path:hover {
  transform: translate(-0.4%, 0.2%);
}

.right path:hover {
  transform: translate(0.4%, 0.2%);
}

.first {
  fill: #fff;
}

.second {
  fill: #fff;
}

.third {
  fill: #fff;
}

.fourth {
  fill: #fff;
}

.alpha {
  fill: #fff;
}

.beta {
  fill: #fff;
}

.gamma {
  fill: #fff;
}

.mind {
  fill: #fff;
}

.body {
  fill: #fff;
}

.spirit {
  fill: #fff;
}

text {
  font: bold 30px sans-serif;
  color: white;
  fill: white;
  z-index: 9999;
  text-anchor: middle;
  dominant-baseline: middle;
}
