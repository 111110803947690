.sticky {
  width: 100vw;
  background-color: black;
  letter-spacing: 0.13em;
  font-weight: 300;
  height: 3.6em;
  position: sticky;
  top: 0px;
}

.aboveSticky, .belowSticky {
  width: 100vw;
  background-color: black;
  height: 1em;
}

.title {
  font-size: larger;
  color: #fff;
  white-space: nowrap;
}

.navLink, .navLink:active, .navLink:hover, .navLink:visited {
  color: #ddd;
  padding: 1em 2em 1em 6em;
}
.navLink {
  transition: text-shadow 0.1s linear, color 0.1s linear;
  text-shadow: none;
}
.navLink:hover {
  text-shadow: 0 0 1px #fff, 0 0 1px #fff;
  color: #fff;
  transition: text-shadow 0.1s linear, color 0.1s linear;
}
.navLink.active {
  text-shadow: 0 0 1px #fff, 0 0 1px #fff;
  color: #fff;
}
.navLink.active:hover {
}

.mainMenu {
  position: absolute;
  z-index: 1000;
  top: 3.6em;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);;
  background-color: black;
}

.mainMenuList {
  display: flex;
  flex-direction: column;
  flex: 0 0 0;
}

.menuItem, .menuHeader {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.menuItem {
  display: none;
  z-index: 1000;
}

.menuTarget:target ~ .sticky .menuItem,
.menuOpen .menuItem {
  display: flex;
}

@media (min-width: 900px) {
  .mainMenu {
    position: static;
  }

  .menuItem {
    display: flex;
  }

  .mainMenuList {
    flex-direction: row;
  }

  .navLink, .navLink:active, .navLink:hover, .navLink:visited {
    color: #ddd;
    padding: 0 1em;
  }
  .navLink {
    transition: text-shadow 0.1s linear, color 0.1s linear;
    text-shadow: none;
  }
  .navLink:hover {
    text-shadow: 0 0 1px #fff, 0 0 1px #fff;
    color: #fff;
    transition: text-shadow 0.1s linear, color 0.1s linear;
  }
  .navLink.active {
    text-shadow: 0 0 1px #fff, 0 0 1px #fff;
    color: #fff;
  }
  .navLink.active:hover {
  }
}

.logo {
  height: 3em;
  padding: 0 1em;
  align-self: center;
}

.topNav {
}

.hamburger {
  display: flex;
  flex-direction: column;
  margin-top: 0.1em;
  padding: 1em 1em 1em 1em;
  align-items: flex-end;
}
@media (min-width: 900px) {
  .hamburger {
    display: none;
  }
}
.menuTarget:target ~ .sticky .backdrop,
.menuOpen .backdrop {
  right: 0;
}
.hamLine {
  display: block;
  width: 1.8em;
  min-height: 0.18em;
  height: 0.18em;
  margin-bottom: 0.35em;
  position: relative;
  background: #cdcdcd;
  border-radius: 999px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.2s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.25s ease;
}
.hamLine:nth-child(1) {
  transform-origin: 0% 0%;
}
.hamLine:nth-child(4) {
  transform-origin: 0% 100%;
}
.menuTarget:target ~ .sticky .hamLine:nth-child(1),
.menuOpen .hamLine:nth-child(1) {
  opacity: 1;
  transform: translate(18%, -0.08em) rotate(45deg);
}
.menuTarget:target ~ .sticky .hamLine:nth-child(3),
.menuOpen .hamLine:nth-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.menuTarget:target ~ .sticky .hamLine:nth-child(4),
.menuOpen .hamLine:nth-child(4) {
  opacity: 1;
  transform: translate(18%, 0.08em) rotate(-45deg);
}
.hamLine:last-child {
  margin-bottom: 0;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.backdrop {
  display: none;
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  cursor: default;
}
.backdrop:active, .backdrop:hover, .backdrop:visited {
  text-decoration: none;
}

@media (min-width: 900px) {
  .backdrop,
  .menuTarget:target ~ .backdrop,
  .menuOpen .backdrop {
    display: none;
  }
}

.menuTarget:target ~ .sticky .backdrop,
.menuOpen .backdrop {
  display: block;
  right: 0;
}
