.artGalleryItem {
  padding: 1.6em 0;
}

.image {
  margin: 0.8em 0;
}

.text {
  margin: 0.8em 0;
}

.img {
  flex: 0 1 auto;
  height: 100%;
  max-height: 80vh;
  width: 100%;
  max-width: 100%;
}
